<template>
  <div>
    <div class="header">账户安全</div>
    <div class="nav"></div>
    <div class="content">
      <div class="li">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/login_pic.png" alt="" />
          </span>
          <span class="sqrc">登录密码</span>
        </div>
        <div class="li-content">
          <span
            >为保证您的账号安全<br />
            建议您定期更改密码以保护账户安全</span
          >
        </div>
        <div class="li-footer">
          <a-button @click="updateLoginPassword">修改密码</a-button>
          <a-button @click="resetLooPwd" style="margin-left: 10px"
            >重置密码</a-button
          >
        </div>
      </div>
      <div class="li">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/jy_pic.png" alt="" />
          </span>
          <span class="sqrc">交易密码</span>
        </div>
        <div class="li-content">
          <span
            >启用交易密码功能<br />
            为您资产账户安全加把锁</span
          >
        </div>
        <div class="li-footer">
          <a-button @click="updateJyPwd">修改密码</a-button>
          <a-button @click="resetPwd" style="margin-left: 10px"
            >重置密码</a-button
          >
        </div>
        <a-modal
          title="信息提示"
          :visible="visible"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <div class="common-msg">
            <a-icon
              style="color: #51c513; font-size: 50px"
              theme="filled"
              type="check-circle"
            />
          </div>
          <div class="common-msg" style="margin-top: 15px; font-size: 20px">
            您的密码已重置！
          </div>
          <div class="common-msg" style="margin-top: 15px">
            我们已向您注册邮箱
            <span style="color: #f15451">{{ email }}</span>
            发送了重置密码，<br />请登录注册邮箱查收。
          </div>
        </a-modal>
      </div>
      <div class="li" v-if="isBindWxCode === 'false'">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/weixin_pic.png" alt="" />
          </span>
          <span class="sqrc">微信登录</span>
        </div>
        <div class="li-content">
          <span>未设置<br />可通过微信扫码绑定</span>
        </div>
        <div class="li-footer">
          <a-button style="margin-left: -100px" @click="bindWx"
            >绑定微信</a-button
          >
        </div>
        <a-modal
          title="信息提示"
          :visible="visibleWx"
          @ok="handleOkWx"
          @cancel="handleCancelWx"
        >
          <div class="wx-code"><div id="qrcode" ref="qrcode"></div></div>
          <div class="wx-content-desc">请用微信扫描以上二维码进行绑定</div>
          <div class="wx-code">
            <a-button @click="getCodeUrl" type="link">刷新二维码</a-button>
          </div>
        </a-modal>
      </div>
      <div class="li" v-if="isBindWxCode === 'true'">
        <div class="li-header">
          <span class="sqr">
            <img src="../../assets/weixin_pic.png" alt="" />
          </span>
          <span class="sqrc">微信登录</span>
        </div>
        <div class="li-content">
          <span>已设置<br />可通过微信扫码登录</span>
        </div>
        <div class="li-footer">
          <a-button style="margin-left: -100px" @click="returnBindWx"
            >解绑微信</a-button
          >
        </div>
      </div>

      <div class="li">
        <div class="li-header">
          <span class="sqr">
            <a-icon type="mobile" style="font-size: 46px" />
          </span>
          <span class="sqrc">手机号</span>
        </div>
        <div class="li-content" style="font-size: 24px; color: #000">
          <span>{{
            phoneInfo.phoneNum.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
          }}</span>
        </div>
        <div class="li-footer">
          <a-button @click="updatePhoneInfo.visiblePhone = true"
            >修改手机号</a-button
          >
          <a-button
            v-if="phoneInfo.phoneCheck === 0"
            @click="phoneInfo.visiblePhone = true"
            style="margin-left: 10px"
            >验证手机号</a-button
          >
          <a-button
            v-if="phoneInfo.phoneCheck !== 0"
            icon="check-circle"
            type="primary"
            style="margin-left: 10px"
          >
            已验证
          </a-button>
        </div>
        <a-modal
          title="验证手机号"
          :visible="phoneInfo.visiblePhone"
          @ok="handleOkPhone"
          @cancel="handleCancelPhone"
        >
          <div class="phoneNum_modal">
            <a-form
              :form="phoneInfo.form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-item label="手机号">
                <a-input
                  placeholder="手机号"
                  style="width: 310px; height: 38px"
                  disabled
                  v-model="phoneInfo.phoneNum"
                />
              </a-form-item>
              <a-form-item label="验证码">
                <div class="ccode">
                  <a-input
                    class="common-input"
                    style="width: 200px; height: 38px"
                    placeholder="输入验证码"
                    max-length="6"
                    v-decorator="[
                      'inputCode',
                      {
                        rules: [{ required: true, message: '请输入验证码!' }],
                      },
                    ]"
                  />
                  <div class="pint">
                    <a-button
                      v-if="!phoneInfo.isSendCode"
                      @click="sendPhoneInfoCode('check')"
                      style="margin-left: 10px; width: 100px; height: 38px"
                      >发送验证码</a-button
                    >
                    <a-button
                      disabled
                      v-if="phoneInfo.isSendCode"
                      style="margin-left: 10px; width: 100px; height: 38px"
                      >{{ phoneInfo.sendTime }}重新发送</a-button
                    >
                  </div>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </a-modal>
        <a-modal
          title="修改手机号"
          :visible="updatePhoneInfo.visiblePhone"
          @ok="handleOkUpdatePhone"
          @cancel="handleCancelUpdatePhone"
        >
          <div class="phoneNum_modal">
            <a-form
              :form="updatePhoneInfo.form"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 12 }"
            >
              <a-form-item label="手机号">
                <a-input
                  placeholder="手机号"
                  style="width: 310px; height: 38px"
                  max-length="11"
                  v-decorator="[
                    'phoneNum',
                    {
                      rules: [{ required: true, message: '请输入新的手机号!' }],
                    },
                  ]"
                />
              </a-form-item>
              <a-form-item label="验证码">
                <div class="ccode">
                  <a-input
                    class="common-input"
                    style="width: 200px; height: 38px"
                    placeholder="输入验证码"
                    max-length="6"
                    v-decorator="[
                      'inputCode',
                      {
                        rules: [{ required: true, message: '请输入验证码!' }],
                      },
                    ]"
                  />
                  <div class="pint">
                    <a-button
                      v-if="!updatePhoneInfo.isSendCode"
                      @click="sendPhoneInfoCode('edit')"
                      style="margin-left: 10px; width: 100px; height: 38px"
                      >发送验证码</a-button
                    >
                    <a-button
                      disabled
                      v-if="updatePhoneInfo.isSendCode"
                      style="margin-left: 10px; width: 100px; height: 38px"
                      >{{ updatePhoneInfo.sendTime }}重新发送</a-button
                    >
                  </div>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import {
  resetPwd,
  getUserBindEmail,
  resetPassword,
  getBindRQCode,
  checkBindWxMq,
  unBindWxMq,
  isChekSyS,
  getPhoneAndeEmail,
  getPhoneVerifiedCode,
  setOrChangePhoneNum,
} from "@/api/api";
import Vue from "vue";
import QRCode from "qrcodejs2";
// import { USER_INFO } from "@/store/mutation-types";
// import JGraphicCode from "@/components/JGraphicCode";
export default {
  // components: {
  // JGraphicCode,
  // },
  data() {
    return {
      userInfo: "",
      email: "",
      visible: false,
      visibleWx: false,
      qrcode: null,
      wxCodeTimer: null,
      intervalTimer: null,
      isReget: false,
      wxCodeKey: "",
      isBindWxCode: "false",
      phoneInfo: {
        phoneNum: "", // 手机号
        visiblePhone: false, // 验证手机号的弹窗
        isSendCode: false,
        sendTime: 60,
        timer: "",
        form: this.$form.createForm(this),
        phoneCheck: 0,
        enterpriseId: "",
      },
      updatePhoneInfo: {
        visiblePhone: false, // 验证手机号的弹窗
        isSendCode: false,
        sendTime: 60,
        timer: "",
        phoneNo: "",
        form: this.$form.createForm(this),
      },
    };
  },
  created() {
    this.checkBindWxMq();
    this.getBindInfo();
  },
  methods: {
    // 修改登录密码
    updateLoginPassword() {
      this.$router.push("/updateLoginPassword");
    },
    // 修改交易密码
    updateJyPwd() {
      this.$router.push("/updateJyPwd");
    },
    // 重置登录密码
    resetLooPwd() {
      let uerInfo = Vue.ls.get("USER_INFO");
      // console.log('555',uerInfo);
      let that = this;
      this.$confirm({
        title: "信息提示",
        content: "确认需要重置登录密码？",
        onOk() {
          return new Promise((resolve, reject) => {
            resetPassword(uerInfo.taxUserLoginId).then((res) => {
              if (res.success) {
                getUserBindEmail().then((ress) => {
                  that.email = ress.message;
                  that.visible = true;
                });
                that.$message.success(res.message);
                resolve();
              } else {
                that.$message.error(res.message);
                resolve();
              }
            });
          }).catch((res) => console.log("Oops errors!", res));
        },
        onCancel() {},
      });
    },
    // 重置交易密码
    resetPwd() {
      let that = this;
      this.$confirm({
        title: "信息提示",
        content: "确认需要重置交易密码？",
        onOk() {
          return new Promise((resolve, reject) => {
            resetPwd().then((res) => {
              if (res.success) {
                getUserBindEmail().then((ress) => {
                  that.email = ress.message;
                  that.visible = true;
                });
                that.$message.success(res.message);
                resolve();
              } else {
                that.$message.error(res.message);
                resolve();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    },
    handleOk(e) {
      this.handleCancel();
    },
    handleCancel(e) {
      this.visible = false;
    },
    // 查询用户是否绑定微信二维码
    checkBindWxMq() {
      checkBindWxMq().then((res) => {
        if (res.success) {
          this.isBindWxCode = res.result.isCheck;
        }
      });
    },
    // 生成二维码
    makeQrcode(text) {
      if (this.qrcode) {
        this.qrcode.clear();
        this.qrcode.makeCode(text);
      } else {
        this.qrcode = new QRCode("qrcode", {
          width: 282, // 设置宽度，单位像素
          height: 282, // 设置高度，单位像素
          text, // 设置二维码内容或跳转地址
        });
      }
    },
    // 获取二维码链接
    getCodeUrl() {
      getBindRQCode().then((res) => {
        let url = res.result.wxMpQrCodeTicket.url;
        this.wxCodeKey = res.result.key;
        this.makeQrcode(url);
      });
    },
    // 校验扫描二维码是否成功
    validWxCodeSuccess() {
      this.intervalTimer = setInterval(() => {
        isChekSyS(this.wxCodeKey).then((res) => {
          // console.log('检验是否成功',res)
          if (res.result.isCheck === "true") {
            // clearInterval(this.intervalTimer)
            this.checkBindWxMq();
            this.handleCancelWx();
          }
        });
      }, 1000);
    },
    // 绑定微信
    bindWx() {
      this.visibleWx = true;
      this.getCodeUrl();
      this.validWxCodeSuccess();
    },
    handleOkWx(e) {
      this.handleCancelWx();
    },
    // 关闭微信二维码
    handleCancelWx(e) {
      if (this.wxCodeTimer !== null) {
        clearTimeout(this.wxCodeTimer);
      }
      if (this.intervalTimer !== null) {
        clearInterval(this.intervalTimer);
      }
      this.visibleWx = false;
    },
    // 解绑微信
    returnBindWx() {
      let that = this;
      this.$confirm({
        title: "解除绑定?",
        content: "解除绑定之后将不能使用微信扫码登录！确定解除绑定吗？",
        onOk() {
          return new Promise((resolve, reject) => {
            unBindWxMq().then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.checkBindWxMq();
                that.qrcode = null;
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        onCancel() {},
      });
    },

    // 查询企业绑定手机号邮箱
    getBindInfo() {
      getPhoneAndeEmail().then((res) => {
        let { bindPhone, enterpriseId, phoneCheck } = res.result;
        this.phoneInfo.phoneNum = bindPhone;
        this.phoneInfo.phoneCheck = phoneCheck;
        this.phoneInfo.enterpriseId = enterpriseId;
      });
    },
    // 点击验证手机号的发送验证码按钮
    sendPhoneInfoCode(val) {
      if (val === "check") {
        getPhoneVerifiedCode({
          enterpriseId: this.phoneInfo.enterpriseId,
          phoneNo: "",
        }).then((res) => {
          if (res.success) {
            this.phoneInfo.isSendCode = true;
            this.phoneInfo.timer = setInterval(() => {
              if (this.phoneInfo.sendTime > 1) {
                this.phoneInfo.sendTime = this.phoneInfo.sendTime - 1;
              } else {
                this.resetPhoneInfoCode(val);
              }
            }, 1000);
          }
        });
      } else if (val === "edit") {
        this.updatePhoneInfo.form.validateFields(
          ["phoneNum"],
          (err, values) => {
            if (!err) {
              getPhoneVerifiedCode({
                enterpriseId: this.phoneInfo.enterpriseId,
                phoneNo: values.phoneNum,
              }).then((res) => {
                if (res.success) {
                  this.updatePhoneInfo.isSendCode = true;
                  this.updatePhoneInfo.timer = setInterval(() => {
                    if (this.updatePhoneInfo.sendTime > 1) {
                      this.updatePhoneInfo.sendTime =
                        this.updatePhoneInfo.sendTime - 1;
                    } else {
                      this.resetPhoneInfoCode(val);
                    }
                  }, 1000);
                }
              });
            }
          }
        );
      }
    },
    // 验证手机号弹窗的发送验证码后时间到了重置默认值
    resetPhoneInfoCode(val) {
      if (val === "check") {
        clearInterval(this.phoneInfo.timer);
        this.phoneInfo.sendTime = 60;
        this.phoneInfo.isSendCode = false;
      } else if (val === "edit") {
        clearInterval(this.updatePhoneInfo.timer);
        this.updatePhoneInfo.sendTime = 60;
        this.updatePhoneInfo.isSendCode = false;
      }
    },
    // 验证手机号的弹窗的确认按钮
    handleOkPhone(e) {
      e.preventDefault();
      this.phoneInfo.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            code: values.inputCode,
            enterpriseId: this.phoneInfo.enterpriseId,
            operation: "check",
            phoneNum: this.phoneInfo.phoneNum,
          };
          setOrChangePhoneNum(params).then((res) => {
            if (res.success) {
              this.handleCancelPhone();
              this.getBindInfo();
            }
          });
        }
      });
    },
    // 验证手机号的弹窗的取消按钮
    handleCancelPhone() {
      this.phoneInfo.form.resetFields();
      this.phoneInfo.visiblePhone = false;
      this.resetPhoneInfoCode("check");
    },

    // 修改手机号的弹窗的确认按钮
    handleOkUpdatePhone(e) {
      e.preventDefault();
      this.updatePhoneInfo.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            code: values.inputCode,
            enterpriseId: this.phoneInfo.enterpriseId,
            operation: "edit",
            phoneNum: values.phoneNum,
          };
          setOrChangePhoneNum(params).then((res) => {
            if (res.success) {
              this.handleCancelUpdatePhone();
              this.getBindInfo();
            }
          });
        }
      });
    },
    // 修改手机号的弹窗的取消按钮
    handleCancelUpdatePhone() {
      this.updatePhoneInfo.form.resetFields();
      this.updatePhoneInfo.visiblePhone = false;
      this.resetPhoneInfoCode("edit");
    },
  },
};
</script>
<style scoped lang="less">
.header {
  color: #283039;
  font-size: 18px;
  font-weight: bold;
}
.nav {
  margin: 15px 0 40px 0;
  color: #283039;
  font-size: 14px;
}
.content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 900px;
  .li {
    width: 430px;
    height: 228px;
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px #eceef3;
    border-radius: 6px;
    padding: 40px 46px 23px 46px;
    margin-bottom: 20px;
    .li-header {
      display: flex;
      align-items: center;
      .sqr {
        width: 46px;
        height: 46px;
        // background: #FFFFFF;
        // border: 2px solid #909090;
        // border-radius: 10px;
        margin-right: 29px;
        img {
          width: 46px;
          height: 46px;
        }
      }
      .sqrc {
        color: #283039;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .li-content {
      color: #9ca0ad;
      font-size: 14px;
      margin: 8px 0 30px 75px;
    }
    .li-footer {
      text-align: center;
    }
  }
}
.common-msg {
  text-align: center;
}
.wx-code {
  display: flex;
  justify-content: center;
}
.wx-content-desc {
  margin: 20px 0;
  text-align: center;
}
.phoneNum_modal {
  .ccode {
    width: 310px;
    display: flex;
    justify-content: space-between;
    .pint {
      cursor: pointer;
      margin-left: 15px;
    }
  }
}
</style>